<template>
  <section class="l-content full relative">
    <l-amap ref="map" :plugins="mapPlugins" @complete="mapComplete"></l-amap>
    <l-absolute :width="250" anchor="left top">
      <l-select v-model="selFenceId" size="large" :data="fenceList" all-label="全部" :prop="['fenceId', 'fenceName']" @change="selectChange"></l-select>
    </l-absolute>
    <l-absolute v-if="!selFenceId" :width="250" :left="265" anchor="left top">
      <el-button icon="el-icon-circle-plus-outline" @click="addFence">添加电子围栏</el-button>
    </l-absolute>
    <l-absolute v-if="selFenceId && selFence" :width="350" :left="265" anchor="left top">
      <el-button icon="el-icon-edit" @click="editFenceName">修改名称</el-button>
      <el-button v-if="editState === 0" type="primary" icon="el-icon-edit" @click="editFenceRoute">修改路劲</el-button>
      <el-button v-if="editState === 0" type="danger" icon="el-icon-circle-close" @click="deleteFenceRoute">删除</el-button>
      <el-button v-if="editState === 1" type="success" icon="el-icon-circle-check" @click="saveFenceRoute">保存</el-button>
      <el-button v-if="editState === 1" type="danger" icon="el-icon-circle-close" @click="cancelFenceRoute">取消</el-button>
    </l-absolute>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import LAmap from '@/lgh/components/amap/'
var mapObj = null
var mapDrawTool = null
var polygonEditor = null
var polygonOption = {
  fillColor: '#00b0ff',
  strokeColor: '#80d8ff'
}
var polygonDic = {}
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarMonitor',
  components: { 'l-amap': LAmap },
  data() {
    return {
      mapPlugins: ['AMap.MouseTool', 'AMap.PolygonEditor'],
      fenceList: null,
      selFenceId: null,
      selFence: null,
      editState: 0,
      markerList: []
    }
  },
  created() {},
  methods: {
    mapComplete(map) {
      mapObj = map
      this.getFenceList()
    },
    mapMarkerClick(data) {
      if (data && data.carId) {
        this.$site.view_car_detail(data.carId)
      }
    },
    selectChange() {
      this.selFence = null
      if (this.selFenceId) {
        this.getSelFenceInfo()
      } else {
        this.getFenceList()
      }
    },
    async getSelFenceInfo() {
      mapObj.clearMap()
      this.selFence = await this.$lgh.get('api/enterprise/car/fence/info', ['enterpriseCode', { fenceId: this.selFenceId }])
      this.editState = 0
      this.fenceToMap(this.selFence, true)
    },
    async getFenceList() {
      mapObj.clearMap()
      this.fenceList = await this.$lgh.get('api/enterprise/car/fence/list', ['enterpriseCode'])
      if (this.selFence) {
        this.selFenceId = this.selFence.fenceId
        this.getSelFenceInfo()
      } else {
        this.fenceList.forEach(this.fenceToMap)
      }
    },
    addFence() {
      this.$lgh.msgPrompt('输入电子围栏名称', p => {
        if (p) {
          this.selFence = {
            enterpriseCode: this.userInfo.enterpriseCode,
            fenceName: p
          }
          this.drawPolygon()
        }
      })
    },
    setPolygonToData(polygon) {
      var paths = polygon.getPath()
      var bCenter = polygon.getBounds().getCenter()
      this.selFence.routeList = paths.map(a => {
        return { lng: a.lng, lat: a.lat }
      })
      this.selFence.route = this.selFence.routeList.map(a => a.lng + ',' + a.lat).join(';')
      this.selFence.centerLng = bCenter.lng
      this.selFence.centerLat = bCenter.lat
    },
    drawPolygon() {
      if (!mapDrawTool) {
        mapDrawTool = new AMap.MouseTool(mapObj)
        mapDrawTool.on('draw', e => {
          this.$lgh.msgConfirm(
            '确认保存?',
            () => {
              this.setPolygonToData(e.obj)
              this.selFence.fenceType = 'polygon'
              mapDrawTool.close(true)
              this.save(() => {
                this.selFence = null
              })
            },
            {
              cancel: () => {
                this.$lgh.toastWarning('绘制取消')
                mapDrawTool.close(true)
              }
            }
          )
        })
      }
      mapDrawTool.polygon(polygonOption)
      this.$lgh.notifySuccess('开始绘制', '点击地图绘制点,双击完成')
    },
    editFenceName() {
      this.$lgh.msgPrompt(
        '输入电子围栏名称',
        p => {
          if (p) {
            this.selFence.fenceName = p
            this.save()
          }
        },
        { inputValue: this.selFence.fenceName }
      )
    },
    editFenceRoute() {
      this.editState = 1
      if (polygonEditor) {
        polygonEditor.close()
        polygonEditor = null
      }
      polygonEditor = new AMap.PolygonEditor(mapObj, polygonDic[this.selFence.fenceId])
      polygonEditor.open()
    },
    deleteFenceRoute() {
      this.$lgh.msgConfirm('确认删除?', p => {
        this.$lgh.get('api/enterprise/car/fence/delete', { enterpriseCode: this.userInfo.enterpriseCode, fenceId: this.selFence.fenceId }).then(res => {
          this.$lgh.toastSuccess('删除成功')
          this.selFence = null
          this.getFenceList()
        })
      })
    },
    saveFenceRoute() {
      if (polygonEditor) {
        polygonEditor.close()
        polygonEditor = null
      }
      this.setPolygonToData(polygonDic[this.selFence.fenceId])
      this.save()
    },
    cancelFenceRoute() {
      this.editState = 0
      if (polygonEditor) {
        polygonEditor.close()
        polygonEditor = null
      }
    },
    fenceToMap(fenceData, ifCenter) {
      // if (ifCenter === true) {
      mapObj.setCenter(new AMap.LngLat(fenceData.centerLng, fenceData.centerLat))
      // }
      var text = new AMap.Text({
        position: new AMap.LngLat(fenceData.centerLng, fenceData.centerLat),
        anchor: 'bottom-center',
        text: fenceData.fenceName,
        style: { 'background-color': 'red', color: '#fff' }
      })
      mapObj.add(text)
      var polygon = new AMap.Polygon(
        Object.assign({
          path: fenceData.routeList.map(a => {
            return new AMap.LngLat(a.lng, a.lat)
          }),
          polygonOption
        })
      )
      mapObj.add(polygon)
      polygonDic[fenceData.fenceId] = polygon
    },
    save(cb) {
      this.$lgh.post('api/enterprise/car/fence/addoredit', this.selFence).then(res => {
        this.$lgh.toastSuccess('保存成功')
        if (cb) cb()
        this.getFenceList()
      })
    }
  }
}
</script>
<style lang="less" scoped></style>
